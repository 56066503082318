<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 bookings-screen full-width gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("bookings.bookings.bookings") }}
        </div>
        <template v-slot:right>
          <router-link :to="{ name: 'r_bookings-search-presets' }">
            <icon icon="#cx-hea1-edit" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
    </template>
    <section v-if="listView && bookingsPresets">
      <list-view
        v-for="bookingsPreset in bookingsPresets"
        :key="bookingsPreset.presetId"
        :bookingsPreset="bookingsPreset"
        @openModal="showSliderModal = true"
      ></list-view>
    </section>
    <slider-modal
      v-if="showSliderModal && modalData.length"
      :show="showSliderModal"
      :data="modalData"
      confirm-button-label="Close"
      :confirm-action="cancelStatusDialog"
      @close="cancelStatusDialog"
    >
      <template v-slot="{ data }">
        <slider-modal-content :data="data"></slider-modal-content>
      </template>
    </slider-modal>
    <screen-modal
      v-if="showDeleteModal"
      class="confirm-modal"
      type="success"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :confirm-action="startDeleteDeclarations"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("bookings.bookings.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("bookings.bookings.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
    <template v-slot:footer>
      <nav
        v-if="deleteDeclarationIds && deleteDeclarationIds.length"
        class="actions-menu theme-gray"
      >
        <ul class="actions-list">
          <li class="action">
            <button class="action-btn" @click="showDeleteModal = true">
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" + deleteDeclarationIds.length + "/" + totalDeclarations + ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import ListView from "@/components/bookings/ListView";
import { DateTime } from "luxon";

export default {
  name: "Bookings",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    ListView,
    SliderModal: defineAsyncComponent(() =>
      import("@/components/global/SliderModal")
    ),
    SliderModalContent: defineAsyncComponent(() =>
      import("@/components/bookings/SliderModalContent")
    )
  },
  data() {
    return {
      listView: true,
      showSliderModal: false,
      showDeleteModal: false
    };
  },
  created() {
    this.setPaginationData(null);
    this.setInitialValues();
  },
  computed: {
    ...mapState("search", ["presets"]),
    ...mapState("bookings", [
      "bookingsPresets",
      "selectedDeclarations",
      "availableHosts"
    ]),
    ...mapState("delegates", ["delegatedBy"]),
    ...mapState("user", ["userData"]),
    routeName() {
      return this.$route;
    },
    modalData() {
      if (
        Object.keys(this.selectedDeclarations) &&
        Object.keys(this.selectedDeclarations).length
      ) {
        return [...new Set(Object.values(this.selectedDeclarations).flat())];
      }
      return [];
    },
    deleteDeclarationIds() {
      let deleteIds = [];
      if (this.bookingsPresets && this.bookingsPresets.length) {
        this.bookingsPresets.forEach(element => {
          if (element.resources && element.resources.length) {
            element.resources.forEach(el => {
              if (el.declarations && el.declarations.length) {
                el.declarations.forEach(e => {
                  if (
                    this.modalData.includes(e.id) &&
                    this.availableHosts.find(z => z.id === e.user_id) &&
                    !deleteIds.find(z => z === e.id)
                  ) {
                    deleteIds.push(e.id);
                  }
                });
              }
            });
          }
        });
      }
      return deleteIds;
    },
    totalDeclarations() {
      var response = 0;

      this.bookingsPresets.forEach(preset => {
        preset.resources.forEach(resource => {
          response += resource.declarations.length;
        });
      });

      return response;
    }
  },
  methods: {
    ...mapActions("search", ["getPresets"]),
    ...mapActions("bookings", ["getBookings", "deleteDeclarations"]),
    ...mapActions("declaration", ["getDeclarationApproveStatuses"]),
    ...mapActions("delegates", ["getDelegations"]),
    ...mapActions("pagination", ["setPaginationData"]),
    setInitialValues() {
      this.$store.commit("global/setIsFullWidthScreen", true, {
        root: true
      });
      this.$store.commit("search/setPresetSection", "booking", {
        root: true
      });
      this.getDeclarationApproveStatuses();
      this.getPresets("booking");
      this.getDelegations(true);
    },
    cancelStatusDialog() {
      this.showSliderModal = false;
    },
    setUsers() {
      let users = [];
      if (this.userData && this.userData.data) {
        users.push(this.userData.data);
      }
      if (this.delegatedBy) {
        this.delegatedBy.forEach(element => {
          if (
            !users.find(el => el.id === element.delegator.id) &&
            element.permission &&
            element.permission.permission === "MY_BOOKINGS" &&
            this.isValidDateTIme(element.datetime_from, element.datetime_to)
          ) {
            users.push(element.delegator);
          }
        });
      }

      this.$store.commit("bookings/setAvailableHosts", users, {
        root: true
      });
    },
    isValidDateTIme(from, to) {
      const dateFrom = DateTime.fromISO(from).startOf("day");
      const dateTo = to ? DateTime.fromISO(to).endOf("day") : null;
      const dateNow = DateTime.now();
      if (
        (dateNow > dateFrom && !dateTo) ||
        (dateNow > dateFrom && dateNow < dateTo)
      ) {
        return true;
      }
      return false;
    },
    startDeleteDeclarations() {
      this.deleteDeclarations(this.deleteDeclarationIds);
    }
  },
  watch: {
    routeName() {
      this.setPaginationData(null);
    },
    presets() {
      this.getBookings({
        resourceIds: null,
        presetId: null,
        extend: null
      });
    },
    delegatedBy() {
      this.setUsers();
    }
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
